import React from 'react'
import Img from 'gatsby-image'
import Navigation from './navigation.js'

import styles from './hero.module.css'
//need to get this in
export default ({ data }) => (
<div>
  <div className={styles.desktopHero}>
    <Navigation excludeMobile={true} />
    <div className={styles.detailsContainer}>
      <div className={styles.heroDetails}>

        <p className={styles.heroTitle}>The Ben Meyer Recovery Foundation was formed by family and friends who came together after the tragic loss of their son/brother/friend to accidental overdose due to fentanyl poisoning.</p>
      </div>
    </div>
  </div>
  <div className={styles.mobileHero}>
    <div className={styles.mobileNav}>
    <Navigation />
    </div>
    <div className={styles.hero}>
    </div>
      <div className={styles.heroDetails}>
        <p className={styles.heroTitle}>The Ben Meyer Recovery Foundation was formed by family and friends who came together after the tragic loss of their son/brother/friend to accidental overdose due to fentanyl poisoning.</p>
      </div>
    </div>
  </div>
)
