import React from 'react';
import { useStaticQuery } from 'gatsby';
import styles from './fentanyl-banner.module.css';

export default function FentanylBanner(props) {
  const data = useStaticQuery(graphql`
    query fentanylBannerQuery {
      allContentfulAsset(filter: {contentful_id: {eq: "dExB4O10nhMhMkwFiW152"}}) {
      edges {
        node {
          file {
            url
          }
        }
      }
    }
    }
  `)

  return (
    <div className={styles.bannerContainer}>
    <div>
      <img className={styles.banner} src={data.allContentfulAsset.edges[0].node.file.url} />
    </div>
    <div style={{margin:'10% 5%'}}>
      <h3>Fentanyl Facts</h3>
      <p style={{textAlign: 'center', maxWidth: '600px', margin: '0 auto 10px auto'}}>Fentanyl is increasingly contaminating the illicit drug supply in the US. Get educated to protect yourself and your loved ones.</p>
      <a href='/fentanyl' style={{borderBottom: '1px solid'}}>Learn More</a>
    </div>


    </div>
  )
}
