import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import NoNavLayout from '../components/no-nav-layout'
import ArticlePreview from '../components/article-preview'
import DonateBanner from '../components/donate-banner'
import ContentBlock from '../components/content-block'
import Container from '../components/container'
import Row from '../components/row'
import HeroSubHeader from '../components/hero-sub-header'
import TwoUpText from '../components/two-up-text'
import JamBanner from '../components/jam-banner'
import InterviewVideo from '../components/interview-video'
import FentanylBanner from '../components/fentanyl-banner'

class BetaIndex extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const contentfulContentBlocks = get(this, 'props.data.allContentfulContentBlock.edges')
    const benPhoto = get(this, 'props.data.allContentfulAsset.edges')
    const benData = contentfulContentBlocks[0].node
    const img1 = this.props.data.allContentfulAsset.edges.find(edge => edge.node.contentful_id=="4G5TUXnvDLOvX2esOGqq5a")
    const img2 = this.props.data.allContentfulAsset.edges.find(edge => edge.node.contentful_id=="3kHdsIgYEbWHDmE4Tye3Eu")


    const section2 = {
      title: "About Ben"
    }

    const whatWeDo = {
      title: 'We work to prevent high-risk substance use, provide support for addiction recovery, and reduce the social stigma of addiction and recovery, in Ben’s honor.'
    }


    return (
      <NoNavLayout location={this.props.location}>

          <Helmet title={siteTitle} />
          <Hero />
          <div style={{display: 'flex', justifyContent: 'center', margin: '5% auto  '}}>
            <InterviewVideo />
          </div>
          <HeroSubHeader data={whatWeDo} />
            <Row>
            <JamBanner />
            </Row>
          <Row>
            <TwoUpText img1={img1} img2={img2}/>
          </Row>
              <FentanylBanner />
          <Row>
            <ContentBlock data={benData} />
          </Row>
          <div style={{height:'10vh'}}></div>
          <Row>
            <DonateBanner />
          </Row>


      </NoNavLayout>
    )
  }
}

export default BetaIndex

export const pageQuery = graphql`
  query BetaQuery {
    allContentfulContentBlock(sort: {order: ASC, fields: order} filter: {page: {eq:"/"}}) {
    edges {
      node {
        backgroundImage {
          file {
            url
          }
        }
        blockImage {
          file {
            url
          }
        }
        contentText {
          contentText
        }
        contentful_id
        title
        link
        linkText
        order
        page
        imagePosition
      }
    }
  }

  allContentfulAsset(filter: {contentful_id: {in: ["3kHdsIgYEbWHDmE4Tye3Eu", "4G5TUXnvDLOvX2esOGqq5a"]}}) {
  edges {
    node {
      file {
        url
      }
      contentful_id
    }
  }
}
  }
`
