import React from 'react'
import { Link } from 'gatsby'

import styles from './content-block.module.css'


class ContentBlock extends React.Component {

  render() {
    const data = this.props.data
    const img = data.blockImage || {file: {url: ''}}
    data.blockImage = img;


    const ContentLink = function(props) {
      return (
        props.data.linkText ? <Link className={styles.contentBlockLink} to={data.link} target='_blank'>{data.linkText}</Link> : <div></div>
      )
    }

    if(data.blockImage.file.url=='') {
      return (
        <div className={styles.contentBlock}>
            <h3 className={styles.contentBlockTitle}>{data.title}</h3>
              <p className={styles.contentBlockText}>{data.contentText.contentText}</p>
            <ContentLink data={data} />
          </div>
      )
    } else if (data.imagePosition=='' || data.imagePosition =='right'){
      return (
        <div className={styles.contentBlock}>
            <h3 className={styles.contentBlockTitle}>{data.title}</h3>
            <div className={styles.contentContainer}>
              <p className={styles.contentBlockText}>{data.contentText.contentText}</p>
              <img className={styles.blockImage} src={data.blockImage.file.url} alt='' />
            </div>
            <ContentLink data={data} />
          </div>
      )
    } else {
      return (
        <div className={styles.contentBlock}>
            <h3 className={styles.contentBlockTitle}>{data.title}</h3>
            <div className={styles.contentContainer}>
              <img className={styles.blockImage} src={data.blockImage.file.url} alt='' />
              <p className={styles.contentBlockText}>{data.contentText.contentText}</p>
            </div>
            <ContentLink data={data} />
          </div>
        )
    }


  }
}


export default ContentBlock
