import React from 'react';
import { useStaticQuery } from 'gatsby';
import styles from './jam-banner.module.css';

export default function() {
  const data = useStaticQuery(graphql`
    query bannerQuery {
      allContentfulAsset(filter: {contentful_id: {eq: "6n1K5SlYNtt4nmdIUDoyBw"}}) {
      edges {
        node {
          file {
            url
          }
        }
      }
    }
    }
  `)

  return (
    <div style={{textAlign:'center'}}>

    <img className={styles.banner} src={data.allContentfulAsset.edges[0].node.file.url}>
    </img>
    <h3>Outdoor Jam 2023</h3>
    <p style={{textAlign: 'center', maxWidth: '600px', margin: '0 auto'}}>The 5-year anniversary Outdoor Jam was a magical weekend in Zion National Park in October, 2023. </p>
    </div>
  )
}
