import React from 'react'
import styles from './interview-video.module.css'

export default function InterviewVideo(props) {


  return (
    <div>
    <div>
    <iframe className={styles.desktopVideo} width="840" height="475" src="https://www.youtube.com/embed/eAkQMfK3r04" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div style={{display: 'flex', justifyContent: 'center'}}>
    <iframe className={styles.mobileVideo} width="320" height="240" src="https://www.youtube.com/embed/eAkQMfK3r04" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    </div>
  )
}
