import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import styles from './two-up-text.module.css'

export default (props) => (
  <div className={styles.twoUpContainer}>
    <div className={styles.card}>
      <img className={styles.cardImg} src={props.img1.node.file.url} />
      <div className={styles.textContainer}>
        <h3>Scholarships</h3>
        <p>We provide scholarships for the continuum of care needed after standard 28-day inpatient rehabilitation providing transitional sober living and clinical services to recovering addicts with limited financial means.</p>
        <a href='/scholarship'>Learn More About the Scholarship</a>
      </div>
    </div>
    <div className={styles.card}>
      <img className={styles.cardImg} src={props.img2.node.file.url} />
      <div className={styles.textContainer}>
        <h3>Education and Awareness</h3>
        <p>We create awareness and provide education and resources regarding substance abuse
and addiction and its impact on the lives of individuals and their families through
educational resources, sharing our stories and creating a community dedicated to
prevention and recovery.</p>
        <a href='/addiction'>Learn More About Addiction</a>
      </div>
    </div>
  </div>
)
