import React from 'react'

import styles from './donate-banner.module.css'

export default ({ data }) => (

  <div className={styles.donateBannerContainer}>
    <h2>Donate</h2>
    <div className={styles.donateBanner}>
          <p>The road to recovery is difficult. For those who cannot afford quality care, it is an especially arduous - if not impossible - climb. Your contribution will help those desperate for a way out to reclaim and rebuild their lives by ensuring they have access to a crucial recovery resource: quality aftercare. Learn more about aftercare <a href='/scholarship'>here</a>.</p>
                      <p>If your employer has a company matching gift program, they may make a matching donation at the link below, and if they have questions or need paperwork completed, they may contact Shauna Trieb at Shauna@BenMeyerFoundation.org. Most employers will match recent donations even if you made the donation before learning about the matching program.</p>
            <div style={{textAlign: 'center', width: '100%'}}>
              <a target='_blank' href="https://benmeyerfoundation.app.neoncrm.com/forms/bmrf"><button>Donate</button></a>
            </div>

      </div>
    </div>
)
